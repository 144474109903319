import { useState } from 'react';
import { useScroll } from './index';
import { isSSR } from '../index';

export type MenuItems = {
  id: string;
  text: string;
}[];

type SideNavMenuItem = {
  id: string;
  text: string;
  active: boolean;
};

type SideNavMenuState = {
  menuItems: SideNavMenuItem[];
  activeId: string;
};

export const useSideNavMenu = (menuItems: MenuItems): SideNavMenuState => {
  if (menuItems.length === 0) {
    throw Error('At least one menu item is required');
  }
  const [activeId, setActiveId] = useState<string>(menuItems[0].id);

  const { direction } = useScroll({ wait: 100 });

  if (!isSSR && direction) {
    const aboutSections = document.getElementsByClassName('side-nav-section');
    let newActiveId;
    let closestScrollDistance: number | undefined;
    for (let i = 0; i < aboutSections.length; i += 1) {
      const section = aboutSections.item(i);
      if (section) {
        // if positive, multiply scroll distance by 4 to favor the section above
        const scrollTop = section.getBoundingClientRect().top;
        const scrollDistance =
          scrollTop >= 0 ? scrollTop * 4 : Math.abs(scrollTop);
        if (
          typeof closestScrollDistance === 'undefined' ||
          scrollDistance < closestScrollDistance
        ) {
          closestScrollDistance = scrollDistance;
          newActiveId = section.id;
        }
      }
    }

    if (newActiveId && newActiveId !== activeId) {
      setActiveId(newActiveId);
    }
  }

  const sideNavItems = menuItems.map(({ id, text }) => ({
    id,
    text,
    active: activeId === id,
  }));

  return {
    menuItems: sideNavItems,
    activeId,
  };
};

export default useSideNavMenu;
