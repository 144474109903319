import React from 'react';
import LinkComponent from '../../linkComponent';
import Star from '../../../assets/vectors/star';
import useSideNavMenu, { MenuItems } from '../../../utils/hooks/sideNavMenu';

type SideNavProps = {
  pageSlug: string;
  menuItems: MenuItems;
};

const SideNavMenu: React.FC<SideNavProps> = ({
  pageSlug,
  menuItems,
}: SideNavProps) => {
  const { menuItems: sideNavMenuItems } = useSideNavMenu(menuItems);
  return (
    <ul className="uppercase font-medium pt-1">
      {sideNavMenuItems.map(({ id, text, active }) => (
        <li className="my-3" key={id}>
          {active && (
            <div className="text-usm-dark-red">
              <div className="inline-block mr-2">
                <Star />
              </div>
              {text}
            </div>
          )}
          {!active && (
            <LinkComponent link={`/${pageSlug}#${id}`} flippedHover>
              {text}
            </LinkComponent>
          )}
        </li>
      ))}
    </ul>
  );
};

export default SideNavMenu;
